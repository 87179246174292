
<template>
  <v-list-item
    selectable
    :class="{ 'grey--text': !organism.result.detected}"
    class="pr-1"
  >
    <v-list-item-icon class="mr-3">
      <organism-detail
        :key="`organism-detail-${organism.id}`"
        :organism="organism"
        @updated="$emit('updated')"
      />
    </v-list-item-icon>
    <v-list-item-content :class="{ 'font-weight-bold': organism.significant && organism.result.detected, 'grey--text': !organism.result.detected }">
      {{ organism.name }}
      <v-expansion-panels
        v-if="organism.result.sensitivities && organism.result.sensitivities.length"
        flat
      >
        <v-expansion-panel
          class="font-weight-regular"
        >
          <v-expansion-panel-header
            class="pa-0 text-overline"
            style="min-height: auto;"
          >
            Sensitivities
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="mx-n6"
          >
            <v-list-item
              v-for="sensitivity in organism.result.sensitivities.filter(x => x.name).sort((a,b) => b.name.en > a.name.en ? -1 : 1)"
              :key="sensitivity.id"
              class="px-0"
              style="min-height: 30px !important;"
            >
              <v-list-item-content class="py-0">
                {{ sensitivity.short_name }}
              </v-list-item-content>
              <span class="text-caption">
                {{ sensitivity.values.mic }}
              </span>
              <v-list-item-action class="my-0">
                <v-icon
                  small
                  :color="sensitivity.values.result == 'S' ? 'success': sensitivity.values.result == 'R' ? 'error' : null"
                >
                  far {{ sensitivity.values.result == 'SDD' ? 'fa-standard-definition' : 'fa-square-' + sensitivity.values.result.toLowerCase() }}
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-list-item-content>
    <v-list-item-icon>
      <i :class="organism.result.detected ? 'fal fa-check' : 'fal fa-times grey--text'" />
    </v-list-item-icon>
  </v-list-item>
</template>
<script>
export default {
  components: {
    OrganismDetail: () => import('@/components/organism/OrganismDetail.vue'),
  },
  props: {
    organism: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
